import React from "react"
import styled from 'styled-components'
import media from '../../styles/media'
import { graphql, useStaticQuery } from 'gatsby'
import { Reveal, Tween } from 'react-gsap'
import Img from 'gatsby-image'

const CaptionedCon = styled.div`
  height: 600px;
  width: 100vw;
  figure {
    margin: 0 5vw;
    position: relative;

    .figure {
      height: 550px;
      overflow: hidden;
    }

    .hero-image, .imgDiv {
      height: 550px;
    }
    
    figcaption {
      text-align: center;
      text-transform: uppercase;
      font-size: 10px;
      line-height: 10px;
      margin-bottom: 10px;
      font-weight: normal;
      span {
        color: #64BF60;
      }
    }
  }

  ${media.mobileL`
    height: 500px;
    width: 90vw;

    margin: 0 5vw;
    display: flex;
    justify-content: center;
    figure {
      position: relative;

      .figure {
        height: 500px;
        width: 90vw;
        overflow: hidden;
      }

      .hero-image, .imgDiv {
        height: 500px;
        width: 90vw;
      }
      
      figcaption {
        text-align: center;
        text-transform: uppercase;
        font-size: 10px;
        line-height: 10px;
        margin-bottom: 10px;
        font-weight: normal;
        span {
          color: #64BF60;
        }
      }
    }
  `}

  ${media.laptop`
    height: 100vh;
    width: auto;
    margin: auto;
    min-width: 700px;
    .text {
      transform: rotate(270deg);
      width: 300px;
      height: 10px;
      p {
        margin-top: 100px;
      }
    }

    .imgCon {
      padding: 50px 0 50px 0;
      figure {
        margin: 0;
        max-height: calc(100vh - 60px);
        position: relative;

        .figure {
          width:400px;
          overflow: hidden;
          height: calc(100vh - 100px);
        }

        .hero-image, .imgDiv {
          max-height: calc(100vh - 100px);
          height: calc(100vh - 100px);
          max-width: 400px;
          margin: 0;
        }
        
        figcaption {
          display: block;
          font-size: 10px;
          line-height: 10px;
          letter-spacing: 0.08em;
          text-transform: uppercase;
          height: 100%;
          max-height: 460px;
          text-align: left;
          position: absolute;
          bottom: 0px;
          left: -30px;
          z-index: -1;
          -ms-writing-mode: tb-rl;
          -webkit-writing-mode: tb-rl;
          writing-mode: tb-rl;
          -webkit-transform: rotate(180deg);
          transform: rotate(180deg) translateY(0%);
          user-select: none;
          span {
            color: #64BF60;
          }
        }
      }
    }
  `}

  ${media.laptopL`
    min-width: 700px;
    .imgCon {
      padding: 50px 0 50px 0;
      figure {
        margin: 0;
        max-height: calc(100vh - 60px);
        position: relative;

        .figure {
          width:500px;
          overflow: hidden;
          height: calc(100vh - 100px);
        }

        .hero-image, .imgDiv {
          max-height: calc(100vh - 100px);
          height: calc(100vh - 100px);
          max-width: 500px;
          margin: 0;
        }
      }
    }   
  `}
`

const Captioned = () => {
  const data = useStaticQuery(graphql`
    query {
      contentfulWebsiteImage(identifier: {eq: "home caption 1"}, sys: {}) {
        imageFile {
          fluid(maxWidth: 980) {
            ...GatsbyContentfulFluid
          } 
        }
        department
        description
      }
    }
  `)

  return ( 
    <CaptionedCon>
      <div className="imgCon">
      <Reveal>
        <figure>
          <Tween from={{ left: '30px' }} duration={1}>
            <figcaption><span>{data.contentfulWebsiteImage.department} </span> / {data.contentfulWebsiteImage.description}</figcaption>
          </Tween>
          <div className="figure">
            <Tween from={{ transform: 'scale(1.2)' }} duration={1} delay={.5}>
              <div className="imgDiv">
                <Img fluid={data.contentfulWebsiteImage.imageFile.fluid} alt="divider" className="hero-image" />
              </div>
            </Tween>
          </div>
        </figure>
      </Reveal>
      </div>
    </CaptionedCon>
  )
}

export default Captioned
