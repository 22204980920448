import React, { useContext } from "react"
import styled from 'styled-components'
import media from '../../styles/media'
import { graphql, useStaticQuery } from 'gatsby'
import ScrollHomeDiv from '../scroll-home'
import { Reveal, Tween } from 'react-gsap'
import { isMobile } from 'react-device-detect'
import LoadContext from '../../context/loadContext'

const BGHome = styled.div`
  ${media.laptop`
    margin-top: 0;
    height: 100vh;
    width: 105vw;
    overflow: hidden;
  `}
`

const BGVideo = styled.video`
  height: 90vh;
  min-height: 700px;

  ${media.mobileL`
    min-height: 450px;
  `}
  
  ${media.laptop`
    height: auto;
    width: 100vw;
    margin-left: 0px;
  `}
`

const HeroCon = styled.div`
  height: 100vh;
  width: 100vw;
  color: white;
  position: absolute;
  top: 0;
  display: flex;
  flex-direction: column;
  align-items: end; 
  justify-content: center;

  .text {
    margin: 0 5vw 0 5vw;
  }

  .heading {
    margin-top: -50px;
    font-size: 36px;
    line-height: 45px;
    font-family: 'Merriweather', serif;
     
  }

  .body {
    font-size: 17px;
    line-height: 30px;
  }

  ${media.mobileL`
    .text {
      margin: 75px 5vw 0 5vw;
    }

    .heading {
      margin-top: -50px;
      font-size: 30px;
      line-height: 40px;
      font-family: 'Merriweather', serif;
       
    }
  `}

  ${media.laptop`
    width: 100vw;
    height: 105vh;

    .text {
      margin-left: 10vw;
    }

    .heading {
       width: 850px;
       font-size: 60px;
       line-height: 85px;
       letter-spacing: 0.01em;
       padding: 0;
     }

     .body {
       font-size: 24px;
       line-height: 34px;
       max-width: 730px;
     }
  `}

  ${media.laptopL`
    width:110vw;
  `}
`

const Hero = () => {
  const loading = useContext(LoadContext)
  const data = useStaticQuery(graphql`
    query {
      contentfulWebsiteImage(identifier: {eq: "home video"}, sys: {}) {
        imageFile {
          file {
            url
          }
        }
      }
    }
  `)

  return (
    <BGHome>
      <BGVideo src={data.contentfulWebsiteImage.imageFile.file.url} id="vid" type="video/mp4" className="background" loop autoPlay playsInline muted />
      <HeroCon>
      {!loading.load && (
        <div className="text">
          <Reveal>
            <Tween from={{ opacity: 0, paddingTop: '5vh' }} duration={1} delay={1}>
              <p className="heading">Professional workwear rental and laundry services</p>
            </Tween>
          </Reveal>
          <Reveal>
            <Tween from={{ opacity: 0, paddingTop: '5vh' }} duration={1} delay={1}>
              <p className="body">ServWorx offers a unique, full spectrum of services from workwear rental and laundry to direct sales of fully customised workwear.</p>
            </Tween>
          </Reveal>
        </div>
      )}
      </HeroCon>
      <ScrollHomeDiv/>
    </BGHome>
  )
}

export default Hero
