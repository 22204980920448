import React from "react"
 
import HorizontalScroll from 'react-scroll-horizontal'

import Hero from './hero'
import Blurb from './blurb'
import Divider from './divider'
import Quote from './quote'
import More from './more'
import Captioned from './captioned'
import Diagonal from './diagonal'
import QuoteTwo from './quoteTwo'
import MobNext from '../mobNext' 
import Next from '../next' 

const HomePage = () => {
  const parent  = { height: `100vh`}

  return (
    <>
    <div style={parent} className="horizontal">
      <HorizontalScroll reverseScroll = { true } >
        <div className="panel">
          <Hero />
        </div>
        <div className="panel">
          <Blurb />
        </div>
        <div className="panel">
          <Divider />
        </div>
        <div className="panel">
          <Quote />
        </div>
        <div className="panel">
          <More />
        </div>
        <div className="panel">
          <Captioned />
        </div>
        <div className="panel">
          <Diagonal />
        </div>
        <div className="panel">
          <QuoteTwo />
        </div>
        <div className="panel">
          <Next />
        </div>
      </HorizontalScroll>
    </div>

    <div className="vertical">
      <Hero />
      <Blurb />
      <Divider />
      <Quote  />
      <More />
      <Captioned />
      <Diagonal />
      <QuoteTwo />
      <MobNext />
    </div>
    </>
  )
}

export default HomePage
