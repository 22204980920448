import React from "react"
import styled,{ keyframes } from 'styled-components'
import media from '../styles/media'

const ScrollIndicate = keyframes`
  0% {
    transform: translateX(-40px);
  }
  100% {
    transform: translateX(40px);
  }
`
const ScrollIndicateDown = keyframes`
  0% {
    transform: translateY(-40px);
  }
  100% {
    transform: translateY(40px);
  }
`

const ScrollCon = styled.div `
  display: flex;
  width: 75px;
  z-index: 3;
  margin-top: -10vh;
  margin-left: 20px;
  z-index:4;
  position: absolute;
  top: 90vh;
  .scroll-text {
    z-index:4;
    color: white;
    font-family: 'neue-haas-grotesk-display', sans-serif;
    text-transform: uppercase;
    font-size: 12px;
    font-weight: normal;
    line-height: 10px;
    margin: 0;
    padding:0;
    width: auto;
      letter-spacing: 1px;
  }
  .indicator-vert {
    z-index:4;
    display: flex;
    height: 30px;
    width: 2px;
    margin-right: 10px;
    margin-top: -10px;
    background-color: white;
    overflow: hidden;
    .fill {
      height: 40px;
      width: 5px;
      background-color: #d3d3d3;
      transform: translateY(-40px);
      animation: ${ScrollIndicateDown} 2s linear infinite;
    }
  }
  .indicator-hori {
    display:none;
  }
  ${media.laptop`
    position: fixed;
    margin: 0;
    display: flex;
    bottom: 20px;
    left: 89vw;
    width: 100px;
    h4 {
      letter-spacing: 1px;
      font-weight: normal;
      font-size: 12px;
    }
    .indicator-hori {
      display: block;
      width: 40px;
      height: 2.5px;
      margin: 3.5px 0 0 6px;
      background-color: white;
      overflow: hidden;
      .fill {
        width: 40px;
        height: 5px;
        background-color: #d3d3d3;
        transform: translateX(-40px);
        animation: ${ScrollIndicate} 2s linear infinite;
      }
    }
    .indicator-vert {
      display: none;
    }
  `}
`

const ScrollHomeDiv = () => {
  return (
    <ScrollCon className="horizontal">
      <div className="indicator-vert">
        <div className="fill"></div>
      </div>
      <h4 className="scroll-text">scroll</h4>
      <div className="indicator-hori">
        <div className="fill"></div>
      </div>
    </ScrollCon>
  )
}

export default ScrollHomeDiv
