import React from "react"
import SEO from '../components/seo'

import HomePage from "../components/HomePage"

const IndexPage = () => {

  return (
    <>
      <SEO 
        title="Home" 
        description="ServWorx offers workwear rental and laundry services in South Africa. We provide high quality, customised and branded workwear to all industries."
        keywords={[` branded workwear`, `workwear rental and laundry services`, `safety workwear`, `customised workwear`, `custom workwear`, `industrial workwear`, `workwear and safety`]} 
      />
      <HomePage />
    </>
  )
}

export default IndexPage
