import React from "react"
import styled from 'styled-components'
import media from '../../styles/media'
import { Reveal, Tween } from 'react-gsap'

const MoreCon = styled.div`
  max-height: 100vh;
  margin: 1em 5vw 8em;
  .text {
    width: auto;
    p {
      margin-bottom: 5vh;
      font-size: 15px;
      line-height: 30px;
       
    }
  }

  ${media.laptop`
    margin: auto 300px auto  0;
    min-width: 500px;
    height: 100vh;
    width: auto;
    display: flex;
    flex-direction: column;
    align-items: center; 
    justify-content: center;

    .text {
      width: 500px;
      margin: 30vh 0;
      p {
        &.top {
        margin-bottom: 10vh;
        }
        font-size: 16px;
        line-height: 26px;
      }
    }
  `}
`

const More = () => {
  return (
    <MoreCon>
      <div className="text">
        <Reveal>
          <Tween from={{ opacity: 0, paddingTop: '5vw' }} duration={.5}>
            <p className="top">With over 90 years of experience in the industry, we have a supreme technical understanding of all things workwear and laundry related.  We understand the health and safety workwear requirements that your business faces, and because of this, we’re able to advise you on the most suitable workwear solutions.</p>
          </Tween>
        </Reveal>
        <Reveal>
          <Tween from={{ opacity: 0, paddingTop: '5vw' }} duration={.5}>
            <p>As the sole distributor of Klopman fabrics, we know that we’re supplying the best of the best. Quality garments that are made to last. So much so, that we guarantee every item that leaves our warehouse.
              Our experience of supplying the food, pharmaceutical and oil &amp; gas industries has made us ensure that our products conform to the strictest quality and hygiene standards.</p>  
          </Tween>
        </Reveal>
      </div>
    </MoreCon>
  )
}

export default More
