import React from "react"
import styled from 'styled-components'
import media from '../../styles/media'
import { graphql, useStaticQuery } from 'gatsby'
import { Reveal, Tween } from 'react-gsap'
import Img from 'gatsby-image'

const DiagonalCon = styled.div`
  width: 100vw;
  padding: 5em 5vw 0;
  font-size: 15px;
  line-height: 30px;
   
  
  .head {
    font-size: 24px;
    line-height: 30px;
    font-family: 'Merriweather', serif;
     
      color: black;
  }

 figure {
    width: 280px;
    height: 280px;
    position: relative;
    margin: 0 0 0 10vw;

    .figure {
      width: 280px;
      overflow: hidden;
      height: 280px;
    }
    
    .hero-image, .imgDiv {
      width: 280px;
      height: 280px;
    }
    img {
      max-height: 280px;
    }
    
    figcaption {
      display: block;
      font-size: 10px;
      line-height: 10px;
      letter-spacing: 0.08em;
      text-transform: uppercase;
      height: 280px;
      max-height: 280px;
      text-align: left;
      position: absolute;
      bottom: 0px;
      margin-left: -20px;
      margin-right: 10px;
      z-index: 1;
      opacity: 1;
      -ms-writing-mode: tb-rl;
      -webkit-writing-mode: tb-rl;
      writing-mode: tb-rl;
      -webkit-transform: rotate(180deg);
      transform: rotate(180deg) translateY(0%);
      user-select: none;
      span {
        color: #64BF60;
        font-size: 10px;
        line-height: 10px;
      }
    }
  }

  .right {
    margin-top:  7em
  }
  .left {
    margin-bottom: 7em;
  }

  ${media.mobileL`
    figure {
      margin: 0 25vw;
    }
  `}

  ${media.laptop`
    height: 100vh;
    width: auto;
    display: flex;
    padding: 10vh 5vw;
    font-size: 16px;
    line-height: 26px;
    margin: 0;
    display: flex;
    flex-direction: row;
    align-items: center; 
    justify-content: center;
    
    .left {
      width: 500px;
      margin: auto 150px auto 150px;
      .head {
      font-size: 25px;
      line-height: 40px;
      }
      p {
        width: 500px;
      }
    }
    figure {
      width: 350px;
      height: 300px;
      position: relative;
      margin: auto 50px;

      .figure {
        width: 350px;
        height: 300px;
        overflow: hidden;
      }
      
      .hero-image, .imgDiv {
        width: 350px;
        height: 300px;
      }
      img {
        height: 300px;
      max-height: 300px;
      }
      
      figcaption {
        display: block;
        font-size: 10px;
        line-height: 10px;
        letter-spacing: 0.08em;
        text-transform: uppercase;
        height: 100%;
        max-height: 300px;
        text-align: left;
        position: absolute;
        bottom: 5px;
        margin-left: -30px;
        margin-right: 0px;
        z-index: -1;
        -ms-writing-mode: tb-rl;
        -webkit-writing-mode: tb-rl;
        writing-mode: tb-rl;
        -webkit-transform: rotate(180deg);
        transform: rotate(180deg) translateY(0%);
        user-select: none;
        span {
          color: #64BF60;
        }
      }
    }
    .right {
      width: 500px;
      margin: auto 150px;
      .head {
      font-size: 25px;
      margin-top: -40px;
      line-height: 40px;
      }
      p {
        width: 500px;
      }
    }
    
  `}

  ${media.laptopL`
    grid-template-columns: 0.5fr 0.5fr 0.5fr;
    grid-template-rows: 1fr 1fr 1fr 1fr;
    font-size: 16px;
    line-height: 26px;
  `}
`

const Diagonal = () => {
  const data = useStaticQuery(graphql`
    query {
      contentfulWebsiteImage(identifier: {eq: "home square"}, sys: {}) {
        imageFile {
          fluid(maxWidth: 980) {
            ...GatsbyContentfulFluid
          }
        }
        department
        description
      }
    }
  `)

  return ( 
    <DiagonalCon>
      <div className="left">
        <Reveal>
          <Tween from={{ opacity: 0, paddingTop: '5vh' }} duration={1}>
            <p className="head">Workwear rental &amp; laundry</p>
          </Tween>
        </Reveal>
        <Reveal>
          <Tween from={{ opacity: 0, paddingTop: '2vh' }} duration={1} delay={.5}>
            <p className="body">Our laundry and rental service take care of the whole process of kitting out your staff. From advising you on the best workwear option for each of your staff to making up custom-sized and branded workwear to weekly drop-off/pick-up of your laundry. Leaving you to focus on what you need to, without worrying about what your staff are wearing. </p>
          </Tween>
        </Reveal>
      </div>
      <Reveal>
        <figure>
          <div className="figure">
            <Tween from={{ transform: 'scale(1.2)' }} duration={1} delay={.5}>
              <div className="imgDiv">
                <Img fluid={data.contentfulWebsiteImage.imageFile.fluid} alt="divider" className="hero-image" />
              </div>
            </Tween>
          </div>
          <Tween from={{ marginLeft: '30px', opacity: 0 }} duration={1}>
            <figcaption><span>{data.contentfulWebsiteImage.department} </span> / {data.contentfulWebsiteImage.description}</figcaption>
          </Tween>
        </figure>
      </Reveal>
      <div className="right">
        <Reveal>
          <Tween from={{ opacity: 0, paddingTop: '5vh' }} duration={1}>
            <p className="head">Direct selling</p>
          </Tween>
        </Reveal>
        <Reveal>
          <Tween from={{ opacity: 0, paddingTop: '2vh' }} duration={1} delay={.5}>
            <p className="body">We sell our garments directly, our custom workwear is manufactured specifically to your requirements, including design, branding, colour, tailored sizing, technical specifications and RFID tagging. We also give the option of doing small runs, if required.</p>
          </Tween>
        </Reveal>
      </div>
    </DiagonalCon>
  )
}

export default Diagonal
