import React from "react"
import styled from 'styled-components'
import media from '../../styles/media'
import { isMobile } from 'react-device-detect'

import { Reveal, Tween } from 'react-gsap'

const QuoteTwoCon = styled.div`
  margin: 6em 6.2vw;

  p {
    font-size: 28px;
    line-height: 45px;
    font-family: 'Merriweather', serif;
    letter-spacing: 0.02em;
  }

  ${media.laptop`
    margin: auto 0 auto 150px;
    min-width: 600px;
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center; 
    justify-content: center;

    .inner {
      width: 50vw;
      max-width: 600px;
      margin: 0 auto;
      color: #323232;
      p {
        font-size: 40px;
        line-height: 64px;
        letter-spacing: 0.01em;
        font-family: 'Merriweather', serif;
        .line {
          margin: 0;
          height: 57px;
          overflow: hidden;
          transform: skewY(0);
        }
      }
    }

  `}

  ${media.laptopL`
    .heading {
      font-size: 40px;
      line-height: 64px;
    }
  `}
`

const QuoteTwo = () => {

  return ( 
    <div id="foo">
      <QuoteTwoCon>
        <Reveal>
        {!isMobile && (
          <div className="inner">
            <p>
              <div className="line">
                <Tween from={{  }} duration={1}>
                <div className="inner"></div>
                </Tween>
              </div>
              <div className="line">
                <Tween from={{ marginTop: "80px", transform: `skewY(7deg)` }} duration={1} delay={.2}>
                <div className="inner">Willing to go above and</div>
                </Tween>
              </div>
              <div className="line">
                <Tween from={{ marginTop: "80px", transform: `skewY(7deg)` }} duration={1} delay={.6}>
                <div className="inner">beyond to meet our</div>
                </Tween>
              </div>
              <div className="line">
                <Tween from={{ marginTop: "80px", transform: `skewY(7deg)` }} duration={1} delay={1}>
                <div className="inner">clients requirements.</div>
                </Tween>
              </div>
            </p>
          </div>
        )}
        {isMobile && (
          <Tween from={{ opacity: 0, paddingTop: '5vh' }} duration={1}>
            <div className="text">
              <p className="heading">Willing to go above and beyond to meet our clients requirements.</p>
            </div>
          </Tween>
        )}
        </Reveal>
      </QuoteTwoCon>
    </div>
  )
}

export default QuoteTwo
